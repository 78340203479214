<template>
    <div>
        {{ date }}
    </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    computed: {
        date() {
            if (this.type === 'started') {
                return format(window.convertFromUtcToLocalTime(this.item.product_started.date), 'MMM DD, YYYY hh:mm A');
            }
            if (this.type === 'ended' && this.item.product_ended) {
                return format(window.convertFromUtcToLocalTime(this.item.product_ended.date), 'MMM DD, YYYY hh:mm A');
            }
            return '--';
        }
    }
};
</script>